import React from "react";
import SEO from "../components/seo";
import Header from "../components/header";
import ContactForm from "../components/contactForm";
import Hr from "../components/hr";
import Layout from "../components/layout";
import SectionHeading from "../components/sectionHeading";
import { 
  Container, 
  Row, 
  Col, 
} from "react-bootstrap";


const Review = () => {

  
  return (
  <Layout>
  
    {/* TODO new above fold component? */}
    <SEO 
      title='Review'
      description='Leave a review of your shed purchase'
      canonical='review'
    />
    <Header active='review'/>
    {/* END new above fold */}
    
    <main id='main'>
      <div className='cg-sectionLight' style={{textAlign:'center'}}>
        <Container fluid>
          <Row>
            <Col>
              <SectionHeading>Leave a Review</SectionHeading>
              <p>Leaving a five-star review will both help us and future customers.</p>
              <p>Important: If you had <strong>anything less</strong> than a 5-star experience, please use 
              the contact form below and give us a chance to fix it before leaving a review.</p>
            </Col>
          </Row>
          {/* TODO 
            0. Hide the Google button. 
            1. First ask how many stars they would give
            2. If they put less than 5, direct to a contact form, asking what we can do to fix it
            3. If it was 5, direct to the Google review
            
          <Row>
            <Col>
              <strong>How would you rate your purchase?</strong>
            </Col>
          </Row>
          <Row>
            <Col>
              stars
            </Col>
          </Row>
          */}
          <Row>
            <Col>
              <br/>
              <a className="cg-dark btn btn-primary" href='https://g.page/UticaShedPros/review?np' target="_blank" rel="noopener noreferrer">Review on Google</a>
              
              <div style={{textAlign:'left', margin:'2rem auto', maxWidth:'20rem', fontSize:'smaller'}}>
                A brief review is best. Just a sentence each:
                <ul>
                  <li>why you needed a shed</li>
                  <li>how the work went</li>
                  <li>how you felt after the project was done</li>
                </ul>
                Thanks!
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      
      <Hr/>
      
      
    </main>
    <ContactForm/>
  </Layout>
)};

export default Review;
